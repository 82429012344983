var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("span", [_vm._v("Add content here.")]),
                _c("span", [_vm._v("User is logged in: ")]),
                !_vm.user
                  ? _c("span", [_vm._v("Not logged in.")])
                  : !_vm.user.mailAddress
                  ? _c("span", [_vm._v("No mailaddress.")])
                  : _c("span", [_vm._v(_vm._s(_vm.user.mailAddress))]),
                _c("p", [
                  _vm._v(
                    " Has user permission to create Users: " +
                      _vm._s(_vm.hasPermissionCreateUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user permission to update Users: " +
                      _vm._s(_vm.hasPermissionUpdateUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user permission to delete Users: " +
                      _vm._s(_vm.hasPermissionDeleteUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user permission to read Users: " +
                      _vm._s(_vm.hasPermissionReadUsers) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user feature to search DPMA: " +
                      _vm._s(_vm.hasFeatureDE) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Has user feature to display Owner: " +
                      _vm._s(_vm.hasFeatureDisplayOwner) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "v-col",
                [
                  _c("v-btn", { attrs: { to: "/search" } }, [
                    _vm._v(" Search "),
                  ]),
                  _c("v-btn", { attrs: { to: "/checkout" } }, [
                    _vm._v(" Checkout "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("VerticalBrandTileGrid", { attrs: { brands: [_vm.dummyBrand] } }),
      _c("HorizontalBrandTileGrid", { attrs: { brands: [_vm.dummyBrand] } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }